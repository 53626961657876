html,
body,
#root {
  width: 100vw;
  height: 100%;
  background-color: #fff;
  margin: 0;
  padding: 0;
  color: rgb(73, 72, 72);
  font-family: sans-serif;
  font-size: 16px;
}

ol {
  padding-inline-start: 0;
}

li {
  list-style: none;
}

p {
  margin: 0;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 0.4em;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-wrapper textarea {
  display: block;
  max-width: 90vw;
  margin: 0.5em 0;
  padding: 0.5em;
}

.submit-button {
  padding: 8px 26px;
  margin-left: auto;
  color: #fff;
  text-align: center;
  background-color: #4d84fa;
  border: none;
  border-radius: 4px;
}

.submit-button:hover {
  background-color: #3969d7;
  cursor: pointer;
}

.responses-heading {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-size: 24px;
  font-weight: bold;
}
.responses-wrapper {
  max-width: min(600px, calc(90vw - 2rem));
  min-width: 100px;
  font-family: arial;
  width: 100%;
}

.thinking {
  display: block;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.history-response-wrapper,
.history-prompt-wrapper {
  display: flex;
}

.history-prompt,
.history-response {
  border-radius: 1rem;
  padding: 0.75rem;
  width: fit-content;
  max-width: calc(100% - 4rem);
  margin-bottom: 1.5rem;
  word-break: break-word;
}

.history-prompt {
  background-color: #4d84fa;
  color: #ffffff;
  border-top-right-radius: 0px;
  margin-left: auto;
}

.history-prompt-wrapper .avatar {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.history-response-wrapper .avatar {
  margin-right: 0.5rem;
  font-size: 1.8rem;
}

.history-response {
  background-color: #e8e8e8;
  color: #333333;
  border-top-left-radius: 0px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e8e8e8;
  margin-bottom: 1.4rem;
}

.empty-state {
  font-size: 1rem;
  font-style: italic;
}
